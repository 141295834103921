<template>
	<layout-default-new>
		<sub-navbar page="Accounting" />
		<div>
			<span class="text-bold">Our Company:</span> &nbsp;{{
				capitalizeWords(reportHeaders.ourCompany)
			}}<br />
			<span class="text-bold">Report Number:</span> &nbsp;{{
				reportHeaders.reportType
			}}{{ reportHeaders.reportNumber }}<br />
			<span class="text-bold">Report Date:</span> &nbsp;{{
				reportHeaders.reportDate
			}}<br />
			<process-report
				:reportHeaders="reportHeaders"
				:report-data="reportData"
			/>
			<div>
				<b-button
					class="is-success margin-top-15"
					@click="onCreateReport"
					style="width: 250px"
				>
					<i class="far fa-plus-square"></i>&nbsp; Create another
					report</b-button
				>
			</div>
			<div>
				<b-button
					class="is-success margin-top-15"
					@click="onCreateInvoiceJournalReport"
					style="width: 250px"
				>
					<i class="far fa-plus-square"></i>&nbsp; Create another invoice
					journal report</b-button
				>
			</div>
			<div>
				<b-button
					class="is-info margin-top-15"
					@click="onViewReport"
					style="width: 250px"
				>
					<i class="fas fa-search"></i>&nbsp; View Another Report
				</b-button>
			</div>
		</div>
	</layout-default-new>
</template>
<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import storeAccounting from '@/_srcv2/pages/accounting/accounting-store/storeAccounting.js'
import Router from '@/router'
import { onMounted } from '@vue/composition-api'
import ProcessReport from '@/components/globals/_base-process-report'
export default {
	name: 'CollectedPaymentsReport',
	components: {
		ProcessReport,
		SubNavbar,
	},
	setup() {
		const capitalizeWords = (string) => {
			return string.replace(/(?:^|\s)\S/g, function (a) {
				return a.toUpperCase()
			})
		}
		// ----------------------------------------------------------------------------
		const { reportData, reportHeaders } = storeAccounting()
		onMounted(() => {
			console.log('----CollectedPaymentsReport is mounted----')
		})
		// ----------------------------------------------------------------------------
		const onViewReport = () => Router.push({ name: 'BookkeepingReportList' })
		const onCreateReport = () => {
			Router.push({ name: 'Accounting' })
		}
		const onCreateInvoiceJournalReport = () => {
			Router.push({ name: 'CreateInvoiceJournal' })
		}
		return {
			onViewReport,
			onCreateReport,
			reportData,
			reportHeaders,
			capitalizeWords,
			onCreateInvoiceJournalReport,
		}
	},
}
</script>
<style scoped>
.margin-top-15 {
	margin-top: 15px;
}
.big-font {
	font-weight: bolder;
	font-size: 2em;
}
</style>
